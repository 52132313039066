/* eslint-disable camelcase */
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
class LeaveMethods {
  methods() {
    return {
      getEntryDateFormat: function (date) {
        return helpers.getEntryDateFormat(date);
      },
      getDateFormat: function (date) {
        return helpers.getDateFormat(date);
      },
      sendApproval(application) {
        /** send for approval */
        const {
          application_code,
          hand_over_to,
          acceptHandover,
          handoverComment,
        } = application;
        const data = {
          employeeNo: this.$store.getters["auth/user"].employee,
          data: { application_code, hand_over_to },
        };
        console.log("sendApproval", application);
        if (this.handOverAccepted({ acceptHandover, handoverComment })) {
          this.$store.dispatch("leave/sendApproval", data);
        } else {
          Event.$emit(
            "ApiError",
            "Your handover request has not been accepted"
          );
        }
      },
      approveApplication(status, application) {
        const data = {
          application_code: this.application.application_code,
          open: true,
          status,
        };
        this.$store.dispatch("leave/setApplicationModal", data);
      },
      setPreview: function (data) {
        this.$store.dispatch("leave/setPreview", data);
      },
      getAttachment: function (application) {
        this.$store.dispatch("leave/getLeaveAttachment", {
          employeeNo: this.$store.getters["auth/user"].employee,
          data: { application_code: application.application_code },
        });
      },
      getApplication: function (params) {
        this.$store.dispatch("leave/getApplication", { ...params });
      },
      getColor: function (status) {
        return helpers.getColor(status);
      },
      getFeedBackColor: function (accept) {
        return helpers.getFeedBackColor(accept);
      },
      goBack: function () {
        this.$router.replace("/leave");
      },
      getLeaveSheet: function (application_code) {
        const data = {
          employeeNo: this.$store.getters["auth/user"].employee,
          data: { application_code },
        };
        this.$store.dispatch("leave/getLeaveSheet", data);
      },
      getApprovalApplications: function () {
        this.$store.dispatch("leave/getApprovalApplications");
      },
      crudAction: function (application, action) {
        const {
          application_code,
          hand_over_to,
          acceptHandover,
          handoverComment,
        } = application;
        const data = {
          employeeNo: this.$store.getters["auth/user"].employee,
          data: { application_code, hand_over_to },
        };
        if (action === "approval") {
          if (this.handOverAccepted({ acceptHandover, handoverComment })) {
            this.$store.dispatch("leave/sendApproval", data);
          } else {
            Event.$emit(
              "ApiError",
              "Your handover request has not been accepted"
            );
          }
        } else if (action === "cancel") {
          this.$store.dispatch("leave/cancelApplication", data);
        } else if (action === "archive") {
          this.$store.dispatch("leave/archiveApplication", data);
        }
      },
      handOverAccepted(item) {
        if (this.handoverRequired === true) {
          return item.acceptHandover;
        } else {
          return true;
        }
      },
      getEmployee: function (applications) {
        applications.map((app) => {
          app.leaveData.made_by = this.leaveEmployeeAll.filter(
            (emp) => emp.number === app.leaveData.employee_no
          )[0];
          app.leaveData.isApprover = this.isApprover;
          app.leaveData.employeeInitials =
            app.leaveData.made_by !== undefined
              ? (
                  app.leaveData.made_by.email[0] +
                  app.leaveData.made_by.email[1]
                )
                  .toString()
                  .toUpperCase()
              : "";
          app.leaveData.fullName =
            app.leaveData.made_by !== undefined
              ? app.leaveData.made_by.first_name +
                " " +
                app.leaveData.made_by.last_name
              : "";
        });
        return applications;
      },

      /** leave calender */
      loading: function (payload) {
        this.$store.dispatch("leave/loading", payload);
      },
      switchViews: function (payload) {
        this.$store.dispatch("leave/switchViews", payload);
      },
      showApplication({ nativeEvent, event }) {
        this.viewApplication(event.leaveData);
        const open = () => {
          this.selectedElement = nativeEvent.target;
          setTimeout(() => {
            this.selectedOpen = true;
          }, 10);
        };
        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        } else {
          open();
        }
        nativeEvent.stopPropagation();
      },
      viewDay({ date }) {
        if (!this.isApproval) {
          this.$router.replace({ path: "make_application" });
        }
      },
      getLeaveType(code) {
        return this.$store.getters["leaveSetup/setupGetter"](
          "leaveTypes"
        ).filter((leaveType) => leaveType.code === code)[0];
      },

      getApplications: function () {
        const applications = this.isApprover
          ? this.$store.getters["leave/leaveGetter"]("approvalApplications")
          : this.$store.getters["leave/leaveGetter"]("applications");
        const leaveEmployees = this.leaveEmployeeAll.filter(
          (emp) => emp.number === this.$store.getters["auth/user"].employee
        )[0];
        const results = [];
        if (leaveEmployees !== undefined) {
          applications.map((app, index) => {
            const approvalEntries = app.approvalEntries.filter(
              (entry) =>
                entry.approver_id === leaveEmployees.user_id &&
                entry.status === "Open" &&
                entry.document_no === app.application_code
            );
            const {
              start_date,
              return_date,
              application_code,
              status,
              leave_code,
            } = app;
            const color =
              this.colors[0].light[
                this.rnd(0, this.colors[0].light.length - 1)
              ];
            app.approvalCount = approvalEntries.length;
            const nestedApplicationCollection = {
              name: application_code,
              start: start_date,
              end: return_date,
              color,
              textColor: this.getDarkColor(color),
              status: status,
              leaveType: `${leave_code} leave`,
              leaveData: app,
              approvalEntries,
            };
            results[index] = nestedApplicationCollection;
          });
        }
        if (this.isApprover) {
          this.applicationsFilter = results.filter(
            (item) => item.leaveData.approvalCount !== 0
          );
          return this.getEmployee(
            results.filter((item) => item.leaveData.approvalCount !== 0)
          ).filter((app) => app.status !== "Open");
        } else {
          const status = this.$route.query.id;
          this.applicationsFilter = results;
          return status === undefined
            ? this.getEmployee(results)
            : this.getEmployee(results).filter(
                (app) => app.status === this.$route.query.id
              );
        }
      },
      getHandOverApplications: function () {
        const applications =
          this.$store.getters["leave/leaveGetter"]("HandOverApprovals");

        const leaveEmployees = this.leaveEmployeeAll.filter(
          (emp) => emp.number === this.$store.getters["auth/user"].employee
        )[0];
        const results = [];
        if (leaveEmployees !== undefined) {
          applications.map((app, index) => {
            const approvalEntries = app.approvalEntries.filter(
              (entry) =>
                entry.approver_id === leaveEmployees.user_id &&
                entry.status === "Open" &&
                entry.document_no === app.application_code
            );
            const {
              start_date,
              return_date,
              application_code,
              status,
              leave_code,
            } = app;
            const color =
              this.colors[0].light[
                this.rnd(0, this.colors[0].light.length - 1)
              ];
            app.approvalCount = approvalEntries.length;
            const nestedApplicationCollection = {
              name: application_code,
              start: start_date,
              end: return_date,
              color,
              textColor: this.getDarkColor(color),
              status: status,
              leaveType: `${leave_code} leave`,
              leaveData: app,
              approvalEntries,
            };
            results[index] = nestedApplicationCollection;
          });
        }
        if (this.isApprover) {
          this.applicationsFilter = results.filter(
            (item) => item.leaveData.approvalCount !== 0
          );
          return this.getEmployee(
            results.filter((item) => item.leaveData.approvalCount !== 0)
          ).filter((app) => app.status !== "Open");
        } else {
          const status = this.$route.query.id;
          this.applicationsFilter = results;
          return status === undefined
            ? this.getEmployee(results)
            : this.getEmployee(results).filter(
                (app) => app.status === this.$route.query.id
              );
        }
      },

      getDarkColor(color) {
        return this.colors[0].dark[this.colors[0].light.indexOf(color)];
      },
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
      submitApplication: function (flag) {
        const {
          startDate,
          no_days,
          comments,
          handoverData,
          leave_type,
          endDate,
          userData,
          attachment,
          ReasonCode,
          ReasonDescription,
        } = this;
        /** submit application + submit application */
        const data = {
          comments: comments,
          days_applied: no_days,
          employee_no: userData.employee,
          end_date: endDate,
          leave_code: leave_type,
          start_date: startDate,
          handoverDescription: handoverData.handoverDescription,
          handoverEmps: JSON.stringify(
            handoverData.hand_over_to !== undefined
              ? handoverData.hand_over_to
              : []
          ),
          attachment,
          ReasonCode,
          ReasonDescription,
        };

        if (this.isEdit) {
          data.application_code = this.editApplicationCode;
          this.$store.dispatch(
            "leave/patchApplication",
            helpers.prepareFormData(data)
          );
        } else {
          if (
            parseInt(this.CalculatAccruedPercentage) >= 50 &&
            parseInt(this.isAllowance)
          ) {
            if (
              this.leaveAllowancePermission.status === "true" &&
              this.leave_type === this.leaveAllowancePermission.leave_type
            ) {
              this.dialog = true;
            } else {
              this.$store.dispatch(
                "leave/submitApplication",
                helpers.prepareFormData(data)
              );
            }
          } else {
            this.$store.dispatch(
              "leave/submitApplication",
              helpers.prepareFormData(data)
            );
          }
        }
      },
      validateStep: function (step) {
        if (step === 1) {
          if (this.leave_type.length === 0) {
            this.leaveTypeValid = "error";
          } else {
            this.leaveTypeValid = "";
            this.step += 1;
          }
        } else if (step === 2) {
          this.validateLeave();
        } else {
          this.step += 1;
        }
      },
      validateLeave: function () {
        if (!this.no_days && !this.end_date) {
          Event.$emit(
            "ApiWarning",
            "Kindly enter a valid date for your end of leave"
          );
        } else {
          const data = {
            leave_type_code: this.leave_type,
            start_date: this.startDate,
            leave_days: this.no_days,
            end_date: this.endDate,
          };
          this.$store.dispatch("leave/validateLeave", data);
        }
      },
      saveAttachment(event) {
        this.attachment = event;
        this.attachmentName = event.name;
      },
      editApplication(application_code) {
        this.$router.replace({
          name: "edit_application",
          params: { application_code },
        });
      },
    };
  }
}

export default new LeaveMethods().methods();
