export default {
  applications: (id) => `hrmis/leave_application?employee_no=${id}`,
  application: (id) => `hrmis/leave_application?application_code=${id}`,
  applicationsAll: "hrmis/leave_application",
  validateLeave: "hrmis/leave_application/validate",
  sendApproval: "hrmis/leave_application/submit",
  patchApplication: "hrmis/leave_application/update",
  cancelApplication: "hrmis/leave_application/cancel",
  archiveApplication: "hrmis/leave_application/archive",
  approve: "hrmis/leave_application/approve",
  reject: "hrmis/leave_application/reject",
  getLeaveSheet: (id) => `cu/ess_cu/getLeaveApplicationReport?number=${id}`,
  getAttachment: "hrmis/leave_application/getLeaveAttachment",
  HandOverApprovals: "hrmis/leave_application/HandOverApprovals",
  patchHandover: "hrmis/leave_application/HandoverOperations",
  handoverReportTemplate: "hrmis/leave_application/handoverReportTemplate",
  handoverPeople: (id) => `hrmis/leave_application/handover_people/${id}`,
};
